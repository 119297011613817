export var APP_VERSION = "(v1.123)";
export var APP_ENV = "stage";
export var API_URL = "https://api.stage.k4mobility.com"
export var REDIRECT_URL = "https://sport.stage.k4mobility.com";
export const OLD_SVC_URL = "https://api.k4mobility.com/api";
export const KAPTURE_BASE_DEV_URL = "https://d7.k4mobility.com/api/public";
export const KAPTURE_BASE_URL = "https://api.k4mobility.com/kapture/public";
export const KAPTUREV2_URL = "https://api.stage.k4mobility.com/kapturev2/public";
export const KAPTUREV2_URL_INV = "https://api.stage.k4mobility.com/kapturev2/";
export const KAPTURE_DOWNLOAD_URL = "https://api.stage.k4mobility.com/kapturev2/";
export const KAPTURE_UI_BASE_URL = "https://kapture.k4mobility.com";
export const FIND_PORTAL_EDGEKLOUD = "https://find.portal.edgekloud.com";
export const EDGE_DATA_URL = "http://52.191.12.119:6060";
export const GRAPHQL_URI = "/data/graphql";
export const GOOGLE_TRACKER_ID = "UA-221108436-3";
export const SITE_CONTROLLER_URL = `${API_URL}/client`;
export const STARLINK_URL = `${API_URL}/starlink`;
export const CAPTIVE_ACCESS_PORTAL_URL = "https://access.dev.k4mobility.com/login";
export const CONFIRM_DIALOG_TITLE = "Confirm";
export const MARK_AS_CLOSED_CONFIRM_DIALOG_SUBTITLE = "Are you sure to close this ticket?";
export const MARK_AS_DETACH_CONFIRM_DIALOG_SUBTITLE = "Are you sure you want to remove the user?";
export const MARK_AS_DETACH_RESOURCE_OU_CONFIRM_DIALOG_SUBTITLE = "Are you sure you want to detach the resource?";
export const MARK_AS_USER_DISABLED_CONFIRM_DIALOG_SUBTITLE = "Are you sure you want to disable the user?";
export const MARK_AS_USER_ENABLED_CONFIRM_DIALOG_SUBTITLE = "Are you sure you want to enable the user?";
export const MARK_AS_RESEND_INVITE_CONFIRM_DIALOG_SUBTITLE = "Are you sure you want to resend invite notification?";
export const MARK_AS_CLOSED_CONFIRM_DIALOG_ALERT_PAGE = "Are you sure to close this alert?";
export const UPDATE_USER_ORGANIZATION = "Updating user organization will remove existing privileges. Are you sure you want to update user origanization?";
export const UPDATE_ORGANIZATION_TWO_FACTOR_AUTH = "Two factor authentication will be applied to all the users present under this organization";
export const UPDATE_ORGANIZATION = "Organization settings will be applied to the current organization and its sub organizations. Are you sure want to proceed?";
export const VITE_USERNAME = "ravieticala";
export const VITE_ACCESS_TOKEN =
    "pk.eyJ1IjoicmF2aWV0aWNhbGEiLCJhIjoiY2t5MGZra2Q5MDBuZTJ2bnJlYmFlZXlvYiJ9.fshbna7GKnaQq2GbY7_q7g";
export const VITE_STYLE_ID = "cl09x552100e015oi9ib0k7ig";
export const SERVICE_FUNCTION_TO_FEATURE = {
    "view-vessel-summary": {
        "service": "serviceportal",
        "function": "ViewVesselSummary"
    },
    "view-service-functions": {
        "service": "serviceportal",
        "function": "ViewServiceFunctions"
    },
    "view-login-history": {
        "service": "serviceportal",
        "function": "ViewLoginHistory"
    },
    "view-maps": {
        "service": "serviceportal",
        "function": "ViewVesselSummary"
    },
    "view-service-management": {
        "service": "kapture",
        "function": "ViewServicePlan"
    },
    "edit-service-plan": {
        "service": "kapture",
        "function": "ModifyServicePlan"
    },
    "view-alerts": {
        "service": "serviceportal",
        "function": "ViewAlert"
    },
    "update-alerts": {
        "service": "serviceportal",
        "function": "UpdateAlert"
    },
    "edge-administration": {
        "service": "serviceportal",
        "function": "EdgeAdministration"
    },
    "view-service-functions": {
        "service": "serviceportal",
        "function": "ViewServiceFunctions"
    },
    "create-ticket": {
        "service": "kapture",
        "function": "CreateTicket"
    },
    "view-tickets": {
        "service": "kapture",
        "function": "ViewTickets"
    },
    "modify-tickets": {
        "service": "kapture",
        "function": "ModifyTickets"
    },
    "manage-ou": {
        "service": "iam",
        "function": "OuAdmin"
    },
    "manage-ou-user": {
        "service": "iam",
        "function": "OuUserAdmin"
    },
    "manage-ou-resource-admin": {
        "service": "iam",
        "function": "ResourceAdmin"
    },
    "view-inventory": {
        "service": "kapture",
        "function": "InventoryRO"
    },
    "beta-feature": {
        "service": "serviceportal",
        "function": "BetaFeatures"
    },
    "starlink-user": {
        "service": "starlink",
        "function": "User"
    },
    "starlink-admin": {
        "service": "starlink",
        "function": "Admin"
    },
    "starlink-only-user": {
        "service": "starlink",
        "function": "StarlinkOnly"
    },
    "starlink-full": {
        "service": "starlink",
        "function": "Full"
    },
};

